import React from 'react';
import Modal from '../../../../components/modal/Modal';
import { Radio, Input, Space } from 'antd';

import { useTranslation } from 'react-i18next';


export const UpdateOrderStatus = ({ updateId, updateProps, handleOnSubmit, updateModalFormRef, onStatusChange, selectedStatus, setCancelationReason, updateStatusBtnLoader, setToggleUpdate, radioArray }) => {

  const { t } = useTranslation();
  return (
    <Modal {...updateProps} className="sidebar__modal--center order-update-modal">
      <form name="update" ref={updateModalFormRef} onSubmit={(event) => handleOnSubmit({ event: event })} className="ant-form ant-form-horizontal">
        <h2 className="text-center mb-4">Ažuriraj status porudžbine:</h2>
        <h3 className="text-center mb-4">Trenutni status: {t(updateId.status)}</h3>
        <Radio.Group onChange={onStatusChange} value={selectedStatus}>
          <Space direction="vertical">
            {radioArray.map((radioItem) => {
              console.log("RADIO ITEM",radioItem)
              let translated = ''
              if(radioItem == "On Delivery"){
                translated = "Prihvati porudžbinu";
              } else if(radioItem == "Delivered") {
                translated = "Dostavljeno";
              }else if(radioItem == "Ordered") {
                translated = "Poručeno";
              } else{
                translated = "Otkaži porudžbinu"
              }
              return (
                <Radio value={radioItem} key={radioItem}>
                  {translated}
                  {selectedStatus === 'Canceled' && radioItem === 'Canceled' ? <Input onChange={(e) => setCancelationReason(e.target.value)} className="cancel-reason required" placeholder="Reason for cancelation" /> : null}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
        <div className="form-group form-group-modal mb-4">
          {!updateStatusBtnLoader ? (
            <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
              <span className="text">Ažuriraj</span>
            </button>
          ) : (
            <button type="button" className="btn btn-primary-outline">
              <span className="text">Ažuriranje...</span>
            </button>
          )}

          <button
            data-cy="modal-delete-cancel-btn"
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setToggleUpdate(false);
            }}
          >
            <span className="text">Odustani</span>
          </button>
        </div>
      </form>
    </Modal>
  );
};
